import { ukToEu } from '../../../lib/util'

/**
 * check groundsole
 *
 * @param {Object} state - current state.
 * @param {Number} soleNr - soleNumber
 */
export function validateGroundSole (state, groundSole, soleNr) {
    if (groundSole.name === '') {
        state[soleNr].soleErrors.groundSole = true
    } else if (state[soleNr].soleErrors.block && state[soleNr].soleErrors.groundSole && !state[soleNr].soleErrors.millingSize) {
        state[soleNr].soleErrors.display = false
        state[soleNr].soleErrors.groundSole = false
        state[soleNr].soleErrors.block = false
    } else {
        state[soleNr].soleErrors.groundSole = false
    }
}

/**
 * check millingSize
 *
 * @param {Object} state - current state.
 * @param {Number} soleNr - soleNumber
 * @param {Object} value - given value.
 */
export function validateMillingSize (state, soleNr, value) {
    const block = state[soleNr].soleData.block

    if ((value === 38 && (!block.key.includes('38mm') && !block.key.includes('3D PU'))) || (value !== 38 && (block.key.includes('38mm') && block.key.includes('3D PU')))) {
        state[soleNr].soleErrors.message.push('Error 401: Verander de Freesblok Hoogte of de Blokkeuze')
        state[soleNr].soleErrors.block = true
        state[soleNr].soleErrors.millingSize = true
    } else {
        state[soleNr].soleErrors.millingSize = false
        if (!state[soleNr].soleErrors.groundSole) {
            state[soleNr].soleErrors.block = false
            state[soleNr].soleErrors.message = state[soleNr].soleErrors.message.filter(e => e.substring(0, 9) !== 'Error 401')
        }
    }
}

/**
 * check block if not empty
 *
 * @param {Object} state - current state.
 * @param {Number} soleNr - soleNumber
 */
export function validateBlock (state, soleNr, action) {
    const block = state[soleNr].soleData.block
    const broadenedFront = state[soleNr].soleData.broadenedFront
    const diagnosis = action.payload.diagnosis

    if (block === '') {
        state[soleNr].soleErrors.block = true
        return
    }
    if (block.key.includes('3D PU') && (broadenedFront >= 5)) {
        state[soleNr].soleErrors.message.push('Error 411: Er kan alleen een voorbladverbreding van 1mm - 4mm worden gekozen bij een 3D PU blok')
        state[soleNr].soleErrors.broadenedFront = true
        state[soleNr].soleErrors.block = true
        return
    } else {
        state[soleNr].soleErrors.broadenedFront = false
        state[soleNr].soleErrors.block = false
        state[soleNr].soleErrors.message = state[soleNr].soleErrors.message.filter(e => e.substring(0, 9) !== 'Error 411')
    }

    if (block === '3D Sport Printzool' && diagnosis.numberOfSoles >= 2) {
        state[soleNr].soleErrors.message.push('Error 412: Er kan maar 1 zool tegelijk gemaakt worden voor de printzool')
    } else {
        state[soleNr].soleErrors.message = state[soleNr].soleErrors.message.filter(e => e.substring(0, 9) !== 'Error 412')
    }

    if (state[soleNr].soleErrors.block && state[soleNr].soleErrors.broadenedFront && !state[soleNr].soleErrors.millingSize) {
        state[soleNr].soleErrors.display = false
        state[soleNr].soleErrors.broadenedFront = false
        state[soleNr].soleErrors.block = false
    }
}

/**
 * check covermaterial if not empty
 *
 * @param {Object} state - current state.
 * @param {Number} soleNr - soleNumber
 * @param {Object} value - given value.
 */
export function validateCoverMaterial (state, soleNr, value) {
    if (value === 'Multicover 4Allbrands (Neskrid)' && (state[soleNr].soleData.leftSoleThickness >= 0.3 || state[soleNr].soleData.rightSoleThickness >= 0.3)) {
        state[soleNr].soleErrors.message.push('Error 410: Voor Neskrid werkschoenen mag de totale zooldikte zonder afdeklaag niet groter zijn dan 1.5mm')
        state[soleNr].soleErrors.coverMaterial = true
    } else {
        if (state[soleNr].soleErrors.message.find(e => e.substring(0, 9) !== 'Error 410') !== undefined) {
            state[soleNr].soleErrors.soleThickness = false
        }
        state[soleNr].soleErrors.message = state[soleNr].soleErrors.message.filter(e => e.substring(0, 9) !== 'Error 410')
        state[soleNr].soleErrors.coverMaterial = false
    }
}

/**
 * check coverLevel if not empty
 *
 * @param {Object} state - current state.
 * @param {Number} soleNr - soleNumber
 * @param {Object} value - given value.
 */
export function validateCoverLevel (state, soleNr, value) {
    if (value === '') {
        state[soleNr].soleErrors.coverLevel = true
    } else {
        state[soleNr].soleErrors.coverLevel = false
    }
}

/**
 * check size with groundsole
 *
 * @param {Object} state - current state.
 * @param {Number} soleNr - soleNumber
 * @param {Object} value - given value.
 * @param {Boolean} isFootSize - true if footsize is filled.
 * @param {Boolean} rightSize - true if rightSize is entered.
 */
export function validateSize (state, soleNr, value, isFootSize, rightSize) {
    if (value === '') return

    const sole = state[soleNr].soleData
    let groundSoleMin = sole.groundSole.min
    let groundSoleMax = sole.groundSole.max

    if (sole.groundSole.name === '') {
        state[soleNr].soleErrors.message.push('Error 403: Selecteer eerst een grondzoolpatroontype')
        setSizeError(state, soleNr, isFootSize, true, true)
        setSizeError(state, soleNr, isFootSize, false, true)
        return
    } else {
        state[soleNr].soleErrors.message = state[soleNr].soleErrors.message.filter(e => e.substring(0, 9) !== 'Error 403')
    }

    if (value > 20 && value % 1 !== 0 && (isFootSize === false || sole.footSizeLeft === sole.soleSizeLeft || sole.footSizeRight === sole.soleSizeRight)) {
        setSizeError(state, soleNr, false, rightSize, true)
        state[soleNr].soleErrors.message.push('Error 409: Zoolmaat moet een geheel getal zijn voor EU maten')
        return
    } else {
        state[soleNr].soleErrors.message = state[soleNr].soleErrors.message.filter(e => e.substring(0, 9) !== 'Error 409')
    }

    // Convert solesizes when sizeSystem is UK and footsize is entered.
    if (isFootSize && sole.groundSole.sizeSystem === 'UK') {
        groundSoleMin = ukToEu(groundSoleMin)
        groundSoleMax = ukToEu(groundSoleMax)
    }

    if ((value < groundSoleMin || value > groundSoleMax)) {
        const block = state[soleNr].soleData.block
        if (block.key.includes('3D PU')) {
            setSizeError(state, soleNr, isFootSize, rightSize, true)
            state[soleNr].soleErrors.message.push(`Error 404: Vul een ${isFootSize ? 'voetmaat' : 'zoolmaat'} tussen ${groundSoleMin} en ${groundSoleMax} in. Kies een ander blok i.v.m. een te grote ${isFootSize ? 'voetmaat' : 'zoolmaat'}.`)
        } else {
            setSizeError(state, soleNr, isFootSize, rightSize, true)
            state[soleNr].soleErrors.message.push(`Error 404: Vul een ${isFootSize ? 'voetmaat' : 'zoolmaat'} tussen ${groundSoleMin} en ${groundSoleMax} in.`)
        }
        return
    } else {
        state[soleNr].soleErrors.message = state[soleNr].soleErrors.message.filter(e => e.substring(0, 27) !== `Error 404: Vul een ${isFootSize ? 'voetmaat' : 'zoolmaat'}`)
    }

    setSizeError(state, soleNr, isFootSize, rightSize, false)
}

/**
 * Set size error
 *
 * @param {Object} state - current state.
 * @param {Number} soleNr - soleNumber
 * @param {Object} errorState - given state to be set to..
 * @param {Boolean} isFootSize - true if footsize is filled.
 * @param {Boolean} rightSize - true if rightSize is entered.
 */
function setSizeError (state, soleNr, isFootSize, rightSize, errorState) {
    if (isFootSize) {
        if (rightSize) {
            state[soleNr].soleErrors.footSizeRight = errorState
        } else {
            state[soleNr].soleErrors.footSizeLeft = errorState
        }
    } else {
        if (rightSize) {
            state[soleNr].soleErrors.soleSizeRight = errorState
        } else {
            state[soleNr].soleErrors.soleSizeLeft = errorState
        }
    }
}

/**
 * check broadenedFront if not lower than 0 or greater than 6
 *
 * @param {Object} state - current state.
 * @param {Number} soleNr - soleNumber
 * @param {Object} value - given value.
 */
export function validateBroadenedFront (state, soleNr, value) {
    const block = state[soleNr].soleData.block
    const fittingData = state[soleNr].soleData.groundSole.fittingData
    const smallestSoleSize = state[soleNr].soleData.soleSizeLeft < state[soleNr].soleData.soleSizeRight ? state[soleNr].soleData.soleSizeLeft : state[soleNr].soleData.soleSizeRight

    if (block.key.includes('3D PU') && (value >= 5)) {
        state[soleNr].soleErrors.message.push('Error 411: Er kan alleen een voorbladverbreding van 1mm - 4mm worden gekozen bij een 3D PU blok')
        state[soleNr].soleErrors.broadenedFront = true
        state[soleNr].soleErrors.block = true
        return
    } else {
        state[soleNr].soleErrors.message = state[soleNr].soleErrors.message.filter(e => e.substring(0, 9) !== 'Error 411')
        state[soleNr].soleErrors.broadenedFront = false
        state[soleNr].soleErrors.block = false
    }

    if (fittingData === undefined) return

    const maxWidth = fittingData.find(item => parseFloat(item.size) === parseFloat(smallestSoleSize))

    if (maxWidth === undefined || value === '') return

    if (value < 0 || value > maxWidth.maxExtraWidth) {
        state[soleNr].soleErrors.message.push('Error 405: Voorblad grootte valt niet tussen 0 en ' + maxWidth.maxExtraWidth)
        state[soleNr].soleErrors.broadenedFront = true
    } else {
        state[soleNr].soleErrors.message = state[soleNr].soleErrors.message.filter(e => e.substring(0, 9) !== 'Error 405')
        state[soleNr].soleErrors.broadenedFront = false
    }
}

/**
 * check soleThickness
 *
 * @param {Object} state - current state.
 * @param {Number} soleNr - soleNumber
 * @param {Object} diagnosis - given diagnosis.
 */
export function validateSoleThickness (state, soleNr, diagnosis) {
    const block = state[soleNr].soleData.block
    const leftSoleThickness = state[soleNr].soleData.leftSoleThickness
    const rightSoleThickness = state[soleNr].soleData.rightSoleThickness

    if (leftSoleThickness === '' && rightSoleThickness === '') {
        state[soleNr].soleErrors.display = false
        state[soleNr].soleErrors.soleThickness = false
        return
    };
    if ((leftSoleThickness > 0.2 || rightSoleThickness > 0.2) && state[soleNr].soleData.coverMaterial === 'Multicover 4Allbrands (Neskrid)') {
        state[soleNr].soleErrors.message.push('Error 410: Voor Neskrid werkschoenen mag de totale zooldikte zonder afdeklaag niet groter zijn dan 1.5mm')
        state[soleNr].soleErrors.soleThickness = true
        return
    } else if (leftSoleThickness > 8.7 || rightSoleThickness > 8.7) {
        state[soleNr].soleErrors.message.push('Error 413: Weet je zeker dat je een totale zooldikte groter dan 10mm wilt gebruiken?')
        state[soleNr].soleErrors.soleThickness = true
        return
    } else {
        state[soleNr].soleErrors.soleThickness = false
        if (state[soleNr].soleErrors.message.find(e => e.substring(0, 9) !== 'Error 410') !== undefined) {
            state[soleNr].soleErrors.coverMaterial = false
        }
    }

    if ((leftSoleThickness < 1 || rightSoleThickness < 1) && block.key.includes('3D PU')) {
        state[soleNr].soleErrors.message.push('Error 414: 3D PU blok mag niet lager zijn dan 2.3mm')
        state[soleNr].soleErrors.soleThickness = true
        return
    }

    state[soleNr].soleErrors.message = state[soleNr].soleErrors.message.filter(e => e.substring(0, 9) !== 'Error 410')
    state[soleNr].soleErrors.message = state[soleNr].soleErrors.message.filter(e => e.substring(0, 9) !== 'Error 413')
    state[soleNr].soleErrors.message = state[soleNr].soleErrors.message.filter(e => e.substring(0, 9) !== 'Error 414')
}

/**
 * Validate all fields if they are not empty and contain no errors.
 *
 * @param {Object} state - current state.
 * @param {Number} soleNr - soleNumber
 */
export function validateAllFields (state, soleNr) {
    const soleError = state[soleNr].soleErrors
    let validate = true
    if (
        soleError.millingSize ||
    soleError.block ||
    soleError.coverMaterial ||
    soleError.coverLevel ||
    soleError.footSizeLeft ||
    soleError.footSizeRight ||
    soleError.soleSizeLeft ||
    soleError.soleSizeRight ||
    soleError.groundSole.name === '' ||
    soleError.broadenedFront ||
    soleError.soleThickness ||
    checkEmptyFields(state, soleNr)
    ) validate = false

    state[soleNr].soleAccordion.validate = validate
}

/**
 * Check if the fields of soleSpecification are empty.
 *
 * @param {Object} state - redux state object
 * @returns {boolean} true
 */
function checkEmptyFields (state, soleNr) {
    const sole = state[soleNr].soleData

    state[soleNr].soleErrors.millingSize = !sole.millingSize
    state[soleNr].soleErrors.block = !sole.block
    state[soleNr].soleErrors.coverMaterial = !sole.coverMaterial
    state[soleNr].soleErrors.coverLevel = !sole.coverLevel
    state[soleNr].soleErrors.footSizeLeft = !sole.footSizeLeft
    state[soleNr].soleErrors.footSizeRight = !sole.footSizeRight
    state[soleNr].soleErrors.soleSizeLeft = !sole.soleSizeLeft
    state[soleNr].soleErrors.soleSizeRight = !sole.soleSizeRight
    state[soleNr].soleErrors.millingSize = !sole.millingSize
    state[soleNr].soleErrors.groundSole = sole.groundSole.name === ''
    state[soleNr].soleErrors.soleType = !sole.soleType

    return !sole.millingSize ||
    !sole.block ||
    !sole.coverMaterial ||
    !sole.coverLevel ||
    !sole.footSizeLeft ||
    !sole.footSizeRight ||
    !sole.soleSizeLeft ||
    !sole.soleSizeRight ||
    sole.groundSole.name === '' ||
    !sole.soleType
}
