/* eslint no-use-before-define: 2 */
import React, { useRef, useState, useEffect } from 'react'
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Grid,
    Select,
    FormControl,
    FormLabel,
    MenuItem,
    Snackbar,
    Paper,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
    Input,
    Fab,
    Button,
    Tooltip,
    Autocomplete
} from '@mui/material'
import { ExpandMore, Add, ErrorOutlineSharp } from '@mui/icons-material'
import useStyles from './style'
import { useSelector, useDispatch } from 'react-redux'
import { fitsAccordion, fitsData } from '../../store/reducers/fitsSpecification'
import { tafelberg, soleResultSuggestionByKey } from '../../store/reducers/soleResult'
import { deepCopy } from '../../lib/util'
import dataProvider from '../../lib/dataProvider'
import { withStyles } from '@mui/styles'


const FitsSpecifications = ({ classes, fitsSpecNumber }) => {
    // local tab context
    const dispatch = useDispatch()
    const orderType = useSelector((state) => state.general.orderType)
    const { patientData } = useSelector((state) => state.general)
    const diagnosis = useSelector((state) => state.diagnosis.diagnosisData)
    const fits = useSelector((state) => state.fitsSpecification[fitsSpecNumber].fitsData)
    const fits0 = useSelector((state) => state.fitsSpecification[0].fitsData)
    const errors = useSelector((state) => state.fitsSpecification[fitsSpecNumber].fitsErrors)
    const tab = useSelector((state) => state.fitsSpecification[fitsSpecNumber].fitsAccordion)
    const fitsWhole = useSelector((state) => state.fitsSpecification)
    const other = useSelector((state) => state.other)

    const upperMaterialOptions = dataProvider.fitsSpecification.upperMaterialOptions
    const outsoleChoices = dataProvider.fitsSpecification.outsoleChoices
    const blockChoices = dataProvider.fitsSpecification.blockChoices
    const fitsSize = dataProvider.fitsSpecification.fitsSize
    const coverMaterial = dataProvider.fitsSpecification.coverMaterial
    const finishingShapeOptions = dataProvider.fitsSpecification.finishingShapeOptions
    const upperPositionOptions = dataProvider.fitsSpecification.upperPositionOptions
    const modelChoices = dataProvider.fitsSpecification.modelChoices
    const widthSizes = dataProvider.fitsSpecification.widthSizes

    useEffect(() => {
        if (orderType === 'fits' && other.dataProvider === 'fits-comfort') {
            dispatch(fitsData({ key: 'block', value: 'EVA 50sh', fitsSpecNumber: 0 }))
            dispatch(fitsData({ key: 'block', value: 'EVA 50sh', fitsSpecNumber: 1 }))
            dispatch(fitsData({ key: 'block', value: 'EVA 50sh', fitsSpecNumber: 2 }))

            dispatch(fitsData({ key: 'finishingShape', value: 'Volledig afdekken', fitsSpecNumber: 0 }))
            dispatch(fitsData({ key: 'finishingShape', value: 'Volledig afdekken', fitsSpecNumber: 1 }))
            dispatch(fitsData({ key: 'finishingShape', value: 'Volledig afdekken', fitsSpecNumber: 2 }))

            dispatch(soleResultSuggestionByKey({ key: 'CV', soleResNumber: 0, value: 10, side: 'left' }))
            dispatch(soleResultSuggestionByKey({ key: 'CV', soleResNumber: 0, value: 10, side: 'right' }))
            dispatch(soleResultSuggestionByKey({ key: 'CV', soleResNumber: 1, value: 10, side: 'left' }))
            dispatch(soleResultSuggestionByKey({ key: 'CV', soleResNumber: 1, value: 10, side: 'right' }))
            dispatch(soleResultSuggestionByKey({ key: 'CV', soleResNumber: 2, value: 10, side: 'left' }))
            dispatch(soleResultSuggestionByKey({ key: 'CV', soleResNumber: 2, value: 10, side: 'right' }))
        } else if (orderType === 'fits') {
            dispatch(fitsData({ key: 'block', value: 'EVA 50sh - 38mm - zwart', fitsSpecNumber: 0 }))
            dispatch(fitsData({ key: 'block', value: 'EVA 50sh - 38mm - zwart', fitsSpecNumber: 1 }))
            dispatch(fitsData({ key: 'block', value: 'EVA 50sh - 38mm - zwart', fitsSpecNumber: 2 }))

            dispatch(fitsData({ key: 'finishingShape', value: 'Volledig afgewerkt', fitsSpecNumber: 0 }))
            dispatch(fitsData({ key: 'finishingShape', value: 'Volledig afgewerkt', fitsSpecNumber: 1 }))
            dispatch(fitsData({ key: 'finishingShape', value: 'Volledig afgewerkt', fitsSpecNumber: 2 }))

            dispatch(soleResultSuggestionByKey({ key: 'CV', soleResNumber: 0, value: 23, side: 'left' }))
            dispatch(soleResultSuggestionByKey({ key: 'CV', soleResNumber: 0, value: 23, side: 'right' }))
            dispatch(soleResultSuggestionByKey({ key: 'CV', soleResNumber: 1, value: 23, side: 'left' }))
            dispatch(soleResultSuggestionByKey({ key: 'CV', soleResNumber: 1, value: 23, side: 'right' }))
            dispatch(soleResultSuggestionByKey({ key: 'CV', soleResNumber: 2, value: 23, side: 'left' }))
            dispatch(soleResultSuggestionByKey({ key: 'CV', soleResNumber: 2, value: 23, side: 'right' }))
        }
    }, other.dataProvider)

    /**
     * Handles the opening and closing of the accordion
     */
    function toggleAccordion() {
        dispatch(fitsAccordion({ key: 'accordion', value: !tab.accordion, fitsSpecNumber }))
    }

    /**
     * Handles the event where a model is selected.
     * Example values are {@code 'teenslipper'} or {@code 'Drieband met hiel'}.
     * @param {!{displayValue:!string, key:!string}} newInputValue
     */
    function onModelSelected(newInputValue) {

        dispatch(fitsData({ key: 'model', value: newInputValue, fitsSpecNumber }))
        dispatch(fitsData({ key: 'upperMaterial', value: '', fitsSpecNumber })) // so when you change the model you have to choose a valid upper material
    }


    /**
     * Setting the cover level
     * @param {Object} coverLevel value for the cover level
     */
    function setFitsData(key, value) {
        dispatch(fitsData({ key, value, fitsSpecNumber }))
    }

    /**
     * Setting the cover level
     * @param {Object} coverLevel value for the cover level
     */
    function setExtraElementData(key, elementName) {
        const elements = [] //deepCopy(fits.extraElement)
        if (fits.extraElement.includes(elementName)) {
            delete elements[elementName]
        } else {
            elements.push(elementName)
        }
        dispatch(fitsData({ key, value: elements, fitsSpecNumber }))
    }

    /**
     * Setting the block level
     */
    function setBlockData(key, newInputValue) {
        dispatch(fitsData({ key, value: newInputValue, fitsSpecNumber }))

        // add fitscomfort exception
        if (other.dataProvider !== 'fits-comfort') {
            if (newInputValue.includes('38mm')) {
                dispatch(fitsData({ key: 'millingSize', value: 38, fitsSpecNumber }))
            } else {
                dispatch(fitsData({ key: 'millingSize', value: 30, fitsSpecNumber }))
            }
        }

        const temp = deepCopy(fitsWhole)
        // add fitscomfort exception
        if (other.dataProvider !== 'fits-comfort') {
            temp[fitsSpecNumber].fitsData.millingSize = newInputValue.includes('38mm') ? 38 : 30
        }

        dispatch(tafelberg({ diagnosis, sole: temp, fits: temp, soleResNumber: fitsSpecNumber, side: 'left', orderType }))
        dispatch(tafelberg({ diagnosis, sole: temp, fits: temp, soleResNumber: fitsSpecNumber, side: 'right', orderType }))
    }

    function onMillSelected(value) {
        dispatch(fitsData({ key: 'millingSize', value, fitsSpecNumber }))

        if (other.dataProvider === 'fits-comfort') {
            const temp = deepCopy(fitsWhole)
            temp[fitsSpecNumber].fitsData.millingSize = value
            dispatch(tafelberg({ diagnosis, sole: temp, fits: temp, soleResNumber: fitsSpecNumber, side: 'left', orderType }))
            dispatch(tafelberg({ diagnosis, sole: temp, fits: temp, soleResNumber: fitsSpecNumber, side: 'right', orderType }))
        }

    }
    /**
     * update foot size
     *
     * @param {String} size Size to set the foot size to.
     * @param {Boolean} rightSize true if right side is filled.
     */
    function onFootSizeInput(size, rightSize = false) {
        let biggestSize
        if (!rightSize) {
            dispatch(fitsData({ key: 'footSizeLeft', value: size, fitsSpecNumber }))
            if (fits.footSizeRight === '' || fits.footSizeRight === fits.footSizeLeft) {
                onFootSizeInput(size, true)
            }
            if ((fits.soleSizeLeft === '' || fits.soleSizeLeft === fits.footSizeLeft || (size > fits.footSizeRight && fits.footSizeRight === fits.soleSizeLeft))) {
                onSoleSizeInput(size)
            }
        } else {
            dispatch(fitsData({ key: 'footSizeRight', value: size, fitsSpecNumber }))
            dispatch(fitsData({ key: 'soleSizeRight', value: size, fitsSpecNumber }))
        }
    }

    function onSoleSizeInput(size, rightSize = false) {
        size = `${size}`.replace(',', '.')
        if (!rightSize) {
            if (fits.soleSizeRight === '' || fits.soleSizeRight === fits.soleSizeLeft) {
                onSoleSizeInput(size, true)
            }
            dispatch(fitsData({ key: 'soleSizeLeft', value: size, fitsSpecNumber }))
        } else {
            dispatch(fitsData({ key: 'soleSizeRight', value: size, fitsSpecNumber }))
        }
    }

    /**
     * update Sole width
     *
     * @param {String} size Size to set the sole size to.
     * @param {Boolean} rightSize true if right side is filled.
     */
    function onSoleWidthChange(size, rightSize = false) {

        if (other.dataProvider === 'fits-comfort') {
            if (!rightSize) {
                if (fits.rightSoleWidth === '' || fits.rightSoleWidth === fits.leftSoleWidth) {
                    dispatch(fitsData({ key: 'leftSoleWidth', value: size, fitsSpecNumber }))
                    dispatch(fitsData({ key: 'rightSoleWidth', value: size, fitsSpecNumber }))
                }
                dispatch(fitsData({ key: 'leftSoleWidth', value: size, fitsSpecNumber }))
            } else {
                dispatch(fitsData({ key: 'rightSoleWidth', value: size, fitsSpecNumber }))
            }
            return
        } else if (!rightSize) {
            if (fits.rightSoleWidth === '' || fits.rightSoleWidth === fits.leftSoleWidth) {
                dispatch(fitsData({ key: 'leftSoleWidth', value: size, fitsSpecNumber }))
                dispatch(fitsData({ key: 'rightSoleWidth', value: size, fitsSpecNumber }))
                updateWidthMessage(fits.soleSizeLeft, size, false)
                updateWidthMessage(fits.soleSizeRight, size, true)
            } else {
                dispatch(fitsData({ key: 'leftSoleWidth', value: size, fitsSpecNumber }))
                updateWidthMessage(fits.soleSizeLeft, size, false)
            }
        } else {
            dispatch(fitsData({ key: 'rightSoleWidth', value: size, fitsSpecNumber }))
            updateWidthMessage(fits.soleSizeRight, size, true)
        }
    }

    /**
     * update Sole width message
     */
    function updateWidthMessage(soleSize, soleWidth, rightSize = false) {
        if (other.dataProvider === 'fits-comfort') return

        const widthMin = 19
        const widthSmall = 29
        const widthNormal = 34
        const widthLarge = 39
        const widthExtraLarge = 44
        const isTeenslipper = fits.model.key !== undefined && fits.model.key.includes('teenslipper')
        const widthMax = isTeenslipper ? 54 : 45.5

        if (isTeenslipper) {
            if (!rightSize) {
                const addValue = soleSize * 1.5
                if (soleWidth < 20) {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: '', fitsSpecNumber }))
                } else if (soleWidth <= widthMin + addValue) {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: 'Te Klein', fitsSpecNumber }))
                } else if (soleWidth <= widthSmall + addValue) {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: 'Small', fitsSpecNumber }))
                } else if (soleWidth <= widthNormal + addValue) {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: 'Normal', fitsSpecNumber }))
                } else if (soleWidth <= widthLarge + addValue) {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: 'Large', fitsSpecNumber }))
                } else if (soleWidth <= widthMax + addValue) {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: 'Extra Large', fitsSpecNumber }))
                } else {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: 'Te Groot', fitsSpecNumber }))
                }
            } else {
                const addValue = soleSize * 1.5
                if (soleWidth < 20) {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: '', fitsSpecNumber }))
                } else if (soleWidth <= widthMin + addValue) {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: 'Te Klein', fitsSpecNumber }))
                } else if (soleWidth <= widthSmall + addValue) {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: 'Small', fitsSpecNumber }))
                } else if (soleWidth <= widthNormal + addValue) {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: 'Normal', fitsSpecNumber }))
                } else if (soleWidth <= widthLarge + addValue) {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: 'Large', fitsSpecNumber }))
                } else if (soleWidth <= widthMax + addValue) {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: 'Extra Large', fitsSpecNumber }))
                } else {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: 'Te Groot', fitsSpecNumber }))
                }
            }
        } else {
            if (!rightSize) {
                const addValue = soleSize * 1.5
                if (soleWidth < 20) {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: '', fitsSpecNumber }))
                } else if (soleWidth < widthMin + addValue) {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: 'Te Klein', fitsSpecNumber }))
                } else if (soleWidth < widthNormal + 0.5 + addValue) {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: 'Small', fitsSpecNumber }))    // added 0.5 to make it the same as in PLTLife
                } else if (soleWidth < widthLarge + 0.5 + addValue) {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: 'Normal', fitsSpecNumber }))
                } else if (soleWidth < widthExtraLarge + 0.5 + addValue) {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: 'Large', fitsSpecNumber }))
                } else if (soleWidth <= widthMax + addValue) {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: 'Extra Large', fitsSpecNumber }))
                } else {
                    dispatch(fitsData({ key: 'leftSoleWidthMessage', value: 'Te Groot', fitsSpecNumber }))
                }
            } else {
                const addValue = soleSize * 1.5
                if (soleWidth < 20) {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: '', fitsSpecNumber }))
                } else if (soleWidth < widthMin + addValue) {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: 'Te Klein', fitsSpecNumber }))
                } else if (soleWidth < widthNormal + 0.5 + addValue) {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: 'Small', fitsSpecNumber }))
                } else if (soleWidth < widthLarge + 0.5 + addValue) {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: 'Normal', fitsSpecNumber }))
                } else if (soleWidth < widthExtraLarge + 0.5 + addValue) {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: 'Large', fitsSpecNumber }))
                } else if (soleWidth <= widthMax + addValue) {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: 'Extra Large', fitsSpecNumber }))
                } else {
                    dispatch(fitsData({ key: 'rightSoleWidthMessage', value: 'Te Groot', fitsSpecNumber }))
                }
            }
        }
    }

    /**
     * Setting up the placeholder for the wijdte links/rechts
     */
    function placeholderWidth(soleSize, model) {

        if (other.dataProvider === 'fits-comfort') {
            const placeholderWidth = widthSizes.find(element => element.size == soleSize)
            if (placeholderWidth === undefined) {
                return ""
            } else {
                return placeholderWidth.min + " - " + placeholderWidth.max
            }

        }

        let placeholder = ""
        const modelTwo = model ? model : ''
        const teenSlipper = modelTwo.key ? modelTwo.key.includes('teenslipper') : false
        const maxValue = teenSlipper ? 54 : 45.5
        const minValue = 19
        if (soleSize && model) {
            const widthMin = (soleSize * 1.5) + minValue
            const widthMax = (soleSize * 1.5) + maxValue

            placeholder = widthMin + " - " + widthMax
        }
        return placeholder
    }

    function changeSoleThickness(value, rightSide = false) {
        let leftSideValue = fits.totalLeftSoleThickness
        let rightSideValue = fits.totalRightSoleThickness
        if (!rightSide) {
            if (fits.totalRightSoleThickness === '' || fits.totalRightSoleThickness === fits.totalLeftSoleThickness) {
                dispatch(fitsData({ key: 'totalRightSoleThickness', value, fitsSpecNumber }))
                dispatch(fitsData({ key: 'totalLeftSoleThickness', value, fitsSpecNumber }))
                leftSideValue = value
                rightSideValue = value
            }
            dispatch(fitsData({ key: 'totalLeftSoleThickness', value, fitsSpecNumber }))
            leftSideValue = value
        } else {
            dispatch(fitsData({ key: 'totalRightSoleThickness', value, fitsSpecNumber }))
            rightSideValue = value
        }

        const temp = deepCopy(fitsWhole)
        temp[fitsSpecNumber].fitsData.totalRightSoleThickness = rightSideValue
        temp[fitsSpecNumber].fitsData.totalLeftSoleThickness = leftSideValue

        dispatch(tafelberg({ diagnosis, sole: temp, fits: temp, soleResNumber: fitsSpecNumber, side: 'left', orderType }))
        dispatch(tafelberg({ diagnosis, sole: temp, fits: temp, soleResNumber: fitsSpecNumber, side: 'right', orderType }))
    }

    /**
     * Setting up the sole type
     *
     * @param {Object} soleType value for the soletype
     */
    function onSoleTypeSelected(soleType) {
        dispatch(fitsData({ key: 'soleType', value: soleType, fitsSpecNumber }))
    }

    /**
     * Copy values of fits 0 into this fits.
     */
    function copyFits() {
        dispatch(fitsData({ key: 'copyFits', value: fits0, fitsSpecNumber }))
    }

    /**
     * Toggle the right sole of display.
     */
    function toggleDisplayRightSole() {
        dispatch(fitsData({ key: 'displayRightSole', value: !fits.displayRightSole, fitsSpecNumber }))
    }

    // removes the value of the bovenwerk when the gender is changed
    // useEffect(() => {
    //     setUpperMaterial('');
    //     updateAppContext();
    // }, [appContext.general.patient?.gender]);

    return (
        <Accordion expanded={tab.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null
                }
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>FITS specificaties - FITS {fitsSpecNumber + 1}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        {
                            fitsSpecNumber !== 0
                                ? <Button size={'small'} variant="outlined" className={classes.copyFits}
                                    onClick={() => copyFits()}>Kopieer FITS 1</Button>
                                : null
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            error={errors.model}
                        >
                            <FormLabel>Model*</FormLabel>
                            <Select
                                value={fits.model || ''}
                                onChange={event => onModelSelected(event.target.value)}
                                className={classes.autoComplete}>
                                {
                                    modelChoices.get(patientData.prefferedSupport).map((map, i) =>
                                        <MenuItem key={i} value={map}>{map.displayValue}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            error={errors.upperMaterial}
                        >
                            <FormLabel>Bovenwerk*</FormLabel>
                            <Autocomplete
                                title="selectUppermaterial"
                                disabled={!fits.model}
                                value={fits.upperMaterial || ''}
                                onChange={(event, value) => setFitsData('upperMaterial', value)}
                                options={upperMaterialOptions.get(patientData.prefferedSupport).get(fits.model.key) || []}
                                className={classes.autoComplete}
                                getOptionLabel={(option) => option || ''}
                                renderInput={(params) =>
                                    <TextField
                                        error={errors.upperMaterial}
                                        {...params} />
                                } />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            error={errors.coverMaterial}
                        >
                            <FormLabel>Afdekmateriaal*</FormLabel>
                            <Autocomplete
                                title="selectCoverMaterial"
                                value={fits.coverMaterial || ''}
                                onChange={(event, value) => setFitsData('coverMaterial', value)}
                                options={coverMaterial}
                                className={classes.autoComplete}
                                getOptionLabel={(option) => option}
                                renderInput={(params) =>
                                    <TextField
                                        error={errors.coverMaterial}
                                        {...params} />
                                } />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            error={errors.block}
                        >
                            <FormLabel>Blokkeuze*</FormLabel>
                            <Select
                                value={fits.block || ''}
                                className={classes.autoComplete}
                                onChange={event => setBlockData('block', event.target.value)}>
                                {
                                    other.dataProvider !== 'fits-comfort' ?
                                        blockChoices.map((map, i) =>
                                            <MenuItem key={i} value={map}>{map}</MenuItem>
                                        )
                                        :
                                        blockChoices.get(fits.millingSize).map((map, i) =>
                                            <MenuItem key={i} value={map}>{map}</MenuItem>
                                        )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    {other.dataProvider !== 'fits-comfort' &&
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={errors.outsole}
                            >
                                <FormLabel>Loopzool*</FormLabel>
                                <Select
                                    value={fits.outsole || ''}
                                    onChange={event => setFitsData('outsole', event.target.value)}
                                    className={classes.autoComplete}>
                                    {
                                        outsoleChoices.map((map, i) =>
                                            <MenuItem key={i} value={map}>{map}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    {other.dataProvider === 'fits-comfort' &&
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={errors.millingSize}
                            >
                                <FormLabel>Hoogte freesblok (mm)*</FormLabel>
                                <Select
                                    value={fits.millingSize || 38}
                                    className={classes.autoComplete}
                                    onChange={(event) => onMillSelected(event.target.value)}>
                                    <MenuItem key={30} value={30}>30</MenuItem>
                                    <MenuItem key={38} value={38}>38</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            error={errors.finishingShape}
                        >
                            <FormLabel>Afwerkingsvorm*</FormLabel>
                            <Select
                                value={fits.finishingShape || ''}
                                onChange={event => setFitsData('finishingShape', event.target.value)}
                                className={classes.autoComplete}>
                                {
                                    finishingShapeOptions.map((map, i) =>
                                        <MenuItem key={i} value={map}>{map}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            error={errors.finishingShape}
                        >
                            <FormLabel>Bovenwerk positie*</FormLabel>
                            <Select
                                value={fits.upperPosition || ''}
                                onChange={event => setFitsData('upperPosition', event.target.value)}
                                className={classes.autoComplete}>
                                {
                                    upperPositionOptions.map((map, i) =>
                                        <MenuItem key={i} value={map}>{map}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    error={errors.footSizeLeft}
                                >
                                    <FormLabel>{fits.displayRightSole ? 'Voetmaat links' : 'Voetmaat'}</FormLabel>
                                    <Input
                                        type="text"
                                        placeholder={fitsSize.min + '-' + fitsSize.max}
                                        value={fits.footSizeLeft}
                                        onChange={(event) => onFootSizeInput(event.target.value, false)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip title="Dit is de schoenmaat die je hebt opgemeten">
                                    <FormControl
                                        fullWidth
                                        error={errors.soleSizeLeft}
                                    >
                                        <FormLabel>{fits.displayRightSole ? 'Zoolmaat links' : 'Zoolmaat'}</FormLabel>
                                        <Input
                                            type="text"
                                            placeholder={fitsSize.min + '-' + fitsSize.max}
                                            value={fits.soleSizeLeft}
                                            onChange={(event) => onSoleSizeInput(event.target.value)}
                                        />
                                    </FormControl>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={errors.leftSoleWidth}
                                >
                                    <FormLabel>{fits.displayRightSole ? 'Wijdtemaat links (in mm)*' : 'Wijdtemaat (in mm)*'}</FormLabel>
                                    <Input
                                        type="text"
                                        placeholder={placeholderWidth(fits.soleSizeLeft, fits.model)}
                                        value={fits.leftSoleWidth}
                                        onChange={event => onSoleWidthChange(event.target.value, false)}
                                    />
                                </FormControl>
                            </Grid>
                            {other.dataProvider !== 'fits-comfort' && <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={errors.leftSoleWidthMessage}>
                                    <FormLabel>{fits.displayRightSole ? 'Wijdte links' : 'Wijdte'}</FormLabel>
                                    <Input type="text" disabled
                                        value={fits.leftSoleWidthMessage}
                                        onChange={(event) => updateWidthMessage(fits.soleSizeLeft, event.target.value, false)}
                                    />
                                </FormControl>
                            </Grid>
                            }
                            {other.dataProvider === 'fits-comfort' && <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={errors.totalLeftSoleThickness}>
                                    <FormLabel>{fits.displayRightSole ? 'Zooldikte links' : 'Zooldikte'}</FormLabel>
                                    <Input type="text"
                                        value={fits.totalLeftSoleThickness}
                                        placeholder='2mm - 6mm'
                                        onChange={(event) => changeSoleThickness(event.target.value, false)}
                                    />
                                </FormControl>
                            </Grid>}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        {fits.displayRightSole
                            ? <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        error={errors.footSizeRight}
                                    >
                                        <FormLabel>Voetmaat rechts*</FormLabel>
                                        <Input
                                            type="text"
                                            placeholder='34-48'
                                            value={fits.footSizeRight}
                                            onChange={(event) => onFootSizeInput(event.target.value, true)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip title="Dit is de schoenmaat die je hebt opgemeten">
                                        <FormControl
                                            fullWidth
                                            error={errors.soleSizeRight}
                                        >
                                            <FormLabel>Zoolmaat rechts*</FormLabel>
                                            <Input
                                                type="text"
                                                placeholder='34-48'
                                                value={fits.soleSizeRight}
                                                onChange={(event) => onSoleSizeInput(event.target.value, true)}
                                            />
                                        </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={errors.rightSoleWidth}
                                    >
                                        <FormLabel>Wijdtemaat rechts (in mm)*</FormLabel>
                                        <Input
                                            type="text"
                                            placeholder={placeholderWidth(fits.soleSizeRight, fits.model.key)}
                                            value={fits.rightSoleWidth}
                                            onChange={event => onSoleWidthChange(event.target.value, true)}
                                        />
                                    </FormControl>
                                </Grid>
                                {other.dataProvider !== 'fits-comfort' && <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={errors.rightSoleWidthMessage}>
                                        <FormLabel>Wijdte rechts</FormLabel>
                                        <Input type="text" disabled
                                            value={fits.rightSoleWidthMessage}
                                            onChange={(event) => updateWidthMessage(fits.soleSizeRight, event.target.value, true)}
                                        />
                                    </FormControl>
                                </Grid>
                                }
                                {other.dataProvider === 'fits-comfort' && <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={errors.totalRightSoleThickness}>
                                        <FormLabel>Zooldikte rechts</FormLabel>
                                        <Input type="text"
                                            value={fits.totalRightSoleThickness}
                                            placeholder='2mm - 6mm'
                                            onChange={(event) => changeSoleThickness(event.target.value, true)}
                                        />
                                    </FormControl>
                                </Grid>}
                            </Grid>
                            : <Grid container className={classes.buttonGrid} spacing={4} direction="column" justifyContent="center" alignItems="center">
                                <Fab color='secondary' aria-label="add" onClick={toggleDisplayRightSole}>
                                    <Add />
                                </Fab>
                                <Typography className={classes.fabText} variant='subtitle2'>
                                    Rechter FITS toevoegen
                                </Typography>
                            </Grid>
                        }
                    </Grid>

                    {other.dataProvider !== 'fits-comfort' && <Grid item xs={3}>
                        <FormControl
                            fullWidth
                            error={errors.extraElement}
                        >
                            <FormLabel>Element toevoegen</FormLabel>
                            <FormControlLabel className={classes.checkBox} label="30sh 3mm EVA - zwart" control={
                                <Checkbox checked={fits.extraElement.includes('30sh 3mm EVA - zwart')} onChange={() => setExtraElementData('extraElement', '30sh 3mm EVA - zwart')} />
                            } />
                        </FormControl>
                    </Grid>}
                    <Grid item xs={3}>
                        <FormControl
                            fullWidth
                            error={errors.soleType}
                        >
                            <FormLabel>FITS keuze*</FormLabel>
                            <RadioGroup row value={fits.soleType || ''}
                                onChange={event => onSoleTypeSelected(event.target.value)} >
                                <FormControlLabel value="podozool" control={<Radio />} label="Podozool" />
                                <FormControlLabel value="orthozool" control={<Radio />} label="Orthozool" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} direction={'row'}>
                        <FormControl
                            fullWidth
                        >
                            <FormLabel>Zool eigenschappen</FormLabel>
                            {other.dataProvider === 'fits-comfort' && <FormControlLabel className={classes.checkBox} label="Opruwen loopzool" control={
                                <Checkbox checked={fits.roughenSole} onChange={() => dispatch(fitsData({ key: 'roughenSole', value: !fits.roughenSole, fitsSpecNumber }))} />
                            } />}
                            <FormControlLabel className={classes.checkBoxBottom} label="Allergievrij lijm" control={
                                <Checkbox checked={fits.hypoallergenicGlue} onChange={() => dispatch(fitsData({ key: 'hypoallergenicGlue', value: !fits.hypoallergenicGlue, fitsSpecNumber }))} />
                            } />
                        </FormControl>
                    </Grid>
                    {
                        fits.displayRightSole &&
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <Button variant="contained" onClick={toggleDisplayRightSole}>Sluit rechts</Button>
                            </FormControl>
                        </Grid>
                    }

                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(FitsSpecifications)
