import { fittingData } from '../../fittingData'

export const millSizes = [
    30, 38
]

export const blockChoices = [
    { key: '3D PU Blok', soleThickness: 1, millingSize: 38, coverMaterial: 'Su\xE8de grijs' },
    {
        key: '3D Sport Printzool',
        soleThickness: 0.5,
        millingSize: 40,
        coverMaterial: '3mm 3D sport',
        suggestion: {
            ASC: '2',
            RCTB: '5',
            SA15: '3',
            CV: '10'
        }
    },
    { key: 'Combi 30 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'Combi 40 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'Combi Sport 35 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'EVA 30 sh - 38mm hoog', soleThickness: 0.7, millingSize: 38, coverMaterial: 'Su\xE8de grijs' },
    { key: 'EVA 40 sh - 38mm hoog', soleThickness: 0.7, millingSize: 38, coverMaterial: 'Su\xE8de grijs' },
    { key: 'EVA 50 sh - 38mm hoog', soleThickness: 0.7, millingSize: 38, coverMaterial: 'Su\xE8de grijs' },
    { key: 'EVA 50 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'EVA 60 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'EVA Sport 50-30-65 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'Supraflex 20 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'Supraflex 30 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'Supraflex 40 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'Supraflex 50 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' }
]

export const coverMaterials = [
    '-',
    'Durafix',
    'Flexline donkerblauw',
    'Flexline geel',
    'Flexline groen',
    'Flexline lichtblauw',
    'Flexline oranje',
    'Flexline paars',
    'Flexline rood',
    'Flexline roze',
    'Flexline zwart',
    'Gladleder beige',
    'Gladleder beige + poron 3.0mm',
    'Gladleder zwart',
    'Kalfsvoering aanperfo beige',
    'Kalfsvoering aanperfo grijs',
    'Kalfsvoering aanperfo zwart',
    'Multicover 4Allbrands (Neskrid)',
    'Sportex blauw',
    'Sportex zwart',
    'Su\xE8de beige',
    'Su\xE8de bruin',
    'Su\xE8de grijs',
    'Su\xE8de zwart'
]

export const coverLevels = {
    'Volledig afdekken': { CoverLeft: true, CoverRight: true },
    'Rechts afdekken + Links los bijleveren': { CoverLeft: false, CoverRight: true },
    'Links afdekken + Rechts los bijleveren': { CoverLeft: true, CoverRight: false },
    'Los bijleveren': { CoverLeft: false, CoverRight: false }
}

export const coverLevelsCompletePair = {
    'Volledig afdekken': { CoverLeft: true, CoverRight: true },
    'Los bijleveren': { CoverLeft: false, CoverRight: false }
}

export const soleCompleteness = [
    'Hele zool',
    'Halve zool',
    'Drie kwart zool'
]

// Doubles please after each other. like PLT Elegant
export const groundSolePatternTypes = [
    {
        name: 'PLT Elegant',
        min: 28,
        max: 50,
        publock: false,
        sizeSystem: 'EU',
        fittingData: fittingData['PLT Elegant']
    },
    {
        name: 'PLT Elegant',
        min: 36,
        max: 47,
        publock: true,
        sizeSystem: 'EU',
        fittingData: fittingData['PLT Elegant']
    },
    {
        name: 'Finn Comfort',
        min: 3.5,
        max: 12.5,
        publock: true,
        sizeSystem: 'UK',
        fittingData: fittingData['Finn Comfort']
    },
    {
        name: 'Finn Comfort',
        min: 1,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['Finn Comfort']
    },
    {
        name: 'PLT Kids',
        min: 20,
        max: 40,
        publock: false,
        sizeSystem: 'EU',
        fittingData: fittingData['PLT Kids']
    },
    {
        name: 'Hartjes',
        min: 3,
        max: 10.5,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData.Hartjes
    },
    {
        name: 'PLT 3D',
        min: 28,
        max: 50,
        publock: false,
        sizeSystem: 'EU',
        fittingData: fittingData['PLT 3D']
    },
    {
        name: 'PLT Slim',
        min: 28,
        max: 50,
        publock: false,
        sizeSystem: 'EU',
        fittingData: fittingData['PLT Slim']
    },
    {
        name: 'PLT Mens Dress',
        min: 36,
        max: 50,
        publock: false,
        sizeSystem: 'EU',
        fittingData: fittingData['PLT Mens Dress']
    },
    {
        name: 'PLT Sport',
        min: 28,
        max: 50,
        publock: false,
        sizeSystem: 'EU',
        fittingData: fittingData['PLT Sport']
    },
    {
        name: 'PLT Small Sport',
        min: 28,
        max: 50,
        publock: false,
        sizeSystem: 'EU',
        fittingData: fittingData['PLT Small Sport']
    },
    {
        name: 'VM - Durea 174',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Durea 174']
    },
    {
        name: 'VM - Durea 178',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Durea 178']
    },
    {
        name: 'VM - Durea 314',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Durea 314']
    },
    {
        name: 'VM - Durea 318',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Durea 318']
    },
    {
        name: 'VM - Durea 814',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Durea 814']
    },
    {
        name: 'VM - Durea 818',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Durea 818']
    },
    {
        name: 'VM - Durea Gijs H404',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Durea Gijs H404']
    },
    {
        name: 'VM - Durea Gijs H408',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Durea Gijs H408']
    },
    {
        name: 'VM - Durea Gijs H704',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Durea Gijs H704']
    },
    {
        name: 'VM - Durea Gijs H708',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Durea Gijs H708']
    },
    {
        name: 'VM - Hartjes Diab',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Hartjes Diab']
    },
    {
        name: 'VM - Meindl Comfort Fit',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Hartjes Diab']
    },
    {
        name: 'VM - Neskrid 443',
        min: 28,
        max: 50,
        publock: false,
        sizeSystem: 'EU',
        fittingData: fittingData['VM - Neskrid 443']
    },
    {
        name: 'VM - Neskrid 522',
        min: 28,
        max: 50,
        publock: false,
        sizeSystem: 'EU',
        fittingData: fittingData['VM - Neskrid 522']
    },
    {
        name: 'VM - Nimco 39K',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Nimco 39K']
    },
    {
        name: 'VM - Nimco 39M',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Nimco 39M']
    },
    {
        name: 'VM - Nimco 39O',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - Nimco 39O']
    },
    {
        name: 'VM - TOM W10',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - TOM W10']
    },
    {
        name: 'VM - TOM W11',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - TOM W11']
    },
    {
        name: 'VM - TOM W12',
        min: 3,
        max: 15,
        publock: false,
        sizeSystem: 'UK',
        fittingData: fittingData['VM - TOM W12']
    },
    {
        name: 'Voetbal',
        min: 28,
        max: 50,
        publock: false,
        sizeSystem: 'EU',
        fittingData: fittingData.Voetbal
    }
]
