/**
 * Create a JSON export for unity modeling.
 *
 * @param {Object} state - the full state of the application.
 */
function unityExport (state) {
    const isFits = state.general.orderType === 'fits'
    const diagnosis = state.diagnosis.diagnosisData
    const soleSpec = state.soleSpecification
    const fitsSpec = state.fitsSpecification
    const soleResInput = state.soleResult
    const soleResSug = state.soleResult

    const unityModel = []
    for (let i = 0; i < diagnosis.numberOfSoles; i++) {
        isFits
            ? unityModel.push({
                PatternName: 'FITS ' + (fitsSpec[i].fitsData.model.displayValue.includes('Teenslipper') ? 'Teenmodel ' : 'Bandenmodel ') + fitsSpec[i].fitsData.leftSoleWidthMessage,
                IsFits: true,
                ShoeSize: parseFloat(fitsSpec[i].fitsData.soleSizeLeft) || 0,
                FootSize: parseFloat(fitsSpec[i].fitsData.footSizeLeft) || 0,
                SoleIncrease: 5,
                WidthIncrease: 0,
                MaxSoleHeight: parseInt(fitsSpec[i].fitsData.millingSize) || 0,
                IsLeft: true,
                ExportType: 'OBJ',
                GridHeightSmoothIterations: 5,
                Elements: unitySoleResultElements(fitsSpec[i], diagnosis, soleResInput[i].soleResultData.left, soleResSug[i].soleResultSuggestion.left, isFits, true)
            })
            : unityModel.push({
                PatternName: soleSpec[i].soleData.groundSole.name,
                IsFits: false,
                ShoeSize: parseFloat(soleSpec[i].soleData.soleSizeLeft),
                FootSize: parseFloat(soleSpec[i].soleData.footSizeLeft) || 0,
                SoleIncrease: parseFloat(soleSpec[i].soleData.totalLeftSoleThickness) || 0,
                WidthIncrease: parseInt(soleSpec[i].soleData.broadenedFront) || 0,
                MaxSoleHeight: soleSpec[i].soleData.block.key.includes('3D PU') ? 50 : (parseInt(soleSpec[i].soleData.millingSize) || 0),
                IsLeft: true,
                ExportType: 'OBJ',
                GridHeightSmoothIterations: 5,
                Elements: unitySoleResultElements(soleSpec[i], diagnosis, soleResInput[i].soleResultData.left, soleResSug[i].soleResultSuggestion.left, isFits, true)
            })
        isFits
            ? unityModel.push({
                PatternName: 'FITS ' + (fitsSpec[i].fitsData.model.displayValue.includes('Teenslipper') ? 'Teenmodel ' : 'Bandenmodel ') + fitsSpec[i].fitsData.rightSoleWidthMessage,
                IsFits: true,
                ShoeSize: parseFloat(fitsSpec[i].fitsData.soleSizeRight) || 0,
                FootSize: parseFloat(fitsSpec[i].fitsData.footSizeRight) || 0,
                SoleIncrease: 5,
                WidthIncrease: 0,
                MaxSoleHeight: parseInt(fitsSpec[i].fitsData.millingSize) || 0,
                IsLeft: false,
                ExportType: 'OBJ',
                GridHeightSmoothIterations: 5,
                Elements: unitySoleResultElements(fitsSpec[i], diagnosis, diagnosis.symmetrical ? soleResInput[i].soleResultData.left : soleResInput[i].soleResultData.right, soleResSug[i].soleResultSuggestion.right, isFits, false)
            })
            : unityModel.push({
                PatternName: soleSpec[i].soleData.groundSole.name,
                IsFits: false,
                ShoeSize: parseFloat(soleSpec[i].soleData.soleSizeRight),
                FootSize: parseFloat(soleSpec[i].soleData.footSizeRight) || 0,
                SoleIncrease: parseFloat(soleSpec[i].soleData.totalRightSoleThickness) || 0,
                WidthIncrease: parseInt(soleSpec[i].soleData.broadenedFront) || 0,
                MaxSoleHeight: soleSpec[i].soleData.block.key.includes('3D PU') ? 50 : (parseInt(soleSpec[i].soleData.millingSize) || 0),
                IsLeft: false,
                ExportType: 'OBJ',
                GridHeightSmoothIterations: 5,
                Elements: unitySoleResultElements(soleSpec[i], diagnosis, diagnosis.symmetrical ? soleResInput[i].soleResultData.left : soleResInput[i].soleResultData.right, soleResSug[i].soleResultSuggestion.right, isFits, false)
            })
    }
    return unityModel
}

/**
* Filter values with a for loop, to get only the values entered OR values with a suggestion.
*
* @param {Object} soleResInput - sole result input values.
* @param {Object} soleResSug - sole result suggesiton values.
* @return {Object} - filtered soleresult elements.
*/
function unitySoleResultElements (soleSpec, diagnosis, soleResInput, soleResSug, isFits, isLeft) {
    const model = []
    for (const [key, value] of Object.entries(soleResInput)) {
        // check the suggestion value
        const suggestionValue = soleResSug[key]

        // skip if tafelberg.
        if (key === 'tafelberg' || key === 'externalHeelLift') continue

        // if fasciPad or ascPad check if the string value is the same as 'true' (result is always true if element is included; false if element is not included; therefore check only result, not suggestion)
        if (key === 'fasciPad') {
            if (value === 'true') {
                model.push({
                    Name: key,
                    NiceName: key,
                    Height: 1.0,
                    Tag: ''
                })
            }
            continue
        }

        // if MIC, also add Ortho/Podozool info
        if (key === 'MIC') {
            const specData = isFits ? soleSpec.fitsData : soleSpec.soleData
            if (value !== '' || suggestionValue !== '') {
                model.push({
                    Name: `${specData.soleType}${diagnosis.diagnosis.id === 12 ? ' TMT - 1' : ''}`,
                    NiceName: `${specData.soleType}${diagnosis.diagnosis.id === 12 ? ' TMT - 1' : ''}`,
                    Height: (value !== '' ? parseInt(value) : parseInt(suggestionValue)) || 0,
                    Tag: key
                })
            }
            continue
        }

        // if fasciPad or ascPad check if the string value is the same as 'true' (result is always true if element is included; false if element is not included; therefore check only result, not suggestion)
        if (key === 'ascPad') {
            if (value === 'true') {
                if (isLeft) {
                    const size = isFits ? soleSpec.fitsData.footSizeLeft : soleSpec.soleData.soleSizeLeft
                    model.push({
                        Name: (size > 28 ? 'ascPad Large' : 'ascPad Normal'),
                        NiceName: (size > 28 ? 'ascPad Large' : 'ascPad Normal'),
                        Height: 1.0,
                        Tag: key
                    })
                } else {
                    const size = isFits ? soleSpec.fitsData.footSizeRight : soleSpec.soleData.soleSizeRight
                    model.push({
                        Name: (size > 28 ? 'ascPad Large' : 'ascPad Normal'),
                        NiceName: (size > 28 ? 'ascPad Large' : 'ascPad Normal'),
                        Height: 1.0,
                        Tag: key
                    })
                }
            }
            continue
        }

        // if CV, offer option for CV small.
        if (key === 'CV') {
            if (value !== '' || suggestionValue !== '') {
                if (isLeft) {
                    model.push({
                        Name: ('CV'),
                        NiceName: ('CV'),
                        Height: (value !== '' ? parseInt(value) : parseInt(suggestionValue)) || 0,
                        Tag: key
                    })
                } else {
                    model.push({
                        Name: ('CV'),
                        NiceName: ('CV'),
                        Height: (value !== '' ? parseInt(value) : parseInt(suggestionValue)) || 0,
                        Tag: key
                    })
                }
            }
            continue
        }

        // if there is not an value entered check for an suggestion.
        if (value === '' || value === null) {
            if (suggestionValue !== '') {
                // not empty, use it
                model.push({
                    Name: key,
                    NiceName: key,
                    Height: parseInt(suggestionValue) || 0,
                    Tag: ''
                })
            }
            continue
        }

        model.push({
            Name: key,
            NiceName: key,
            Height: parseInt(value),
            Tag: ''
        })
    }

    model.forEach((element, i) => {
        if (element.Height === 0) {
            model.splice(i, 1)
        }
    })

    return model
}
export { unityExport }
