import { initialState as initialStateFits } from '../store/reducers/fitsSpecification/initialState'
import { initialState as initialStateSole } from '../store/reducers/soleSpecification/initialState'
import { deepCopy } from './util'
import { distance } from 'closest-match'

import data from '../tabs/diagnosis/diagnose'
import dataProvider from './dataProvider'

const fitsCoverMaterials = dataProvider.fitsSpecification.coverMaterial
const finishingShapeOptions = dataProvider.fitsSpecification.finishingShapeOptions
const modelChoices = dataProvider.fitsSpecification.modelChoices
const upperMaterialOptions = dataProvider.fitsSpecification.upperMaterialOptions
const groundSolePatternTypes = dataProvider.soleSpecification.groundSolePatternTypes
const soleCoverMaterials = dataProvider.soleSpecification.coverMaterials
const soleBlockChoices = dataProvider.soleSpecification.blockChoices

/**
 * Change the import to work on this current version
 *
 * @param {Object} event - full JSON import file.
 * @return state
 */
function importSoleAndFits (event) {
    const finalImport = deepCopy(event)

    finalImport.general.fittingLocation.id = parseInt(finalImport.general.fittingLocation.id)
    finalImport.general.receiveLocation.id = parseInt(finalImport.general.receiveLocation.id)

    let diagnosis = data.getDiagnoseByName(finalImport.diagnosis.diagnosis.name)
    if (diagnosis === undefined) {
        // get a diagnosis with the full string
        diagnosis = data.getClosestsDiagnosis(finalImport.diagnosis.diagnosis.name)

        // if diagnosis is still undefined, check if which diagnosis is closest to the name.
        if (diagnosis === undefined) {
            let closestDiagnosisMatch = { name: '' }
            const parsedDiagnosisName = finalImport.diagnosis.diagnosis.name.replace(/[^a-zA-Z]+/g, '')
            data.diagnoses.forEach(element => {
                const elementParsedName = element.name.replace(/[^a-zA-Z]+/g, '')
                const distanceNumber = distance(elementParsedName, parsedDiagnosisName)
                if (distanceNumber < distance(closestDiagnosisMatch.name.replace(/[^a-zA-Z]+/g, ''), parsedDiagnosisName)) {
                    closestDiagnosisMatch = element
                }
            })

            // distance margin
            if (distance(closestDiagnosisMatch.name.replace(/[^a-zA-Z]+/g, ''), parsedDiagnosisName) < 4) {
                finalImport.diagnosis.diagnosis = closestDiagnosisMatch
            }
        } else {
            finalImport.diagnosis.diagnosis = diagnosis
        }
    } else {
        finalImport.diagnosis.diagnosis = diagnosis
    }

    if (finalImport.diagnosis.extraDiagnosis === undefined) {
        finalImport.diagnosis.extraDiagnosis = []
    }

    if (finalImport.diagnosis.completePair === undefined) {
        finalImport.diagnosis.completePair = 'both'
    }

    //  fix import for old version
    if (event.version <= '1.4.5') {
        // make sole result and sole result suggestion an array, that is used like an array now.
        finalImport.soleResult = deepCopy([event.soleResult, event.soleResult, event.soleResult])
        finalImport.soleResultSuggestion = deepCopy([event.soleResultSuggestion, event.soleResultSuggestion, event.soleResultSuggestion])
    }

    // Fix preffered support to be the same as gender because default it was male.
    if (event.version <= '1.5.9') {
        finalImport.general.prefferedSupport = finalImport.general.gender
    }

    // get current naming of model choices
    if (event.orderType === 'fits' || event.orderType === 'fitsCustom') {
        const gender = event.general.prefferedSupport !== undefined ? event.general.prefferedSupport : event.general.gender

        finalImport.fitsSpecification.forEach((fitsSpec, i) => {
            const fitsNumber = i

            if (event.version <= '1.5.3') {
                finalImport.fitsSpecification[fitsNumber].extraElement = finalImport.fitsSpecification[fitsNumber].extraElement === true ? ['30sh 3mm EVA - zwart'] : []
            }

            if (event.version <= '1.5.5') {
                const fitsNumber = i
                finalImport.fitsSpecification[fitsNumber].millingSize = finalImport.fitsSpecification[fitsNumber].millSize
                delete finalImport.fitsSpecification[fitsNumber].millSize
            }

            if (event.version <= '1.5.9') {
                finalImport.fitsSpecification[fitsNumber].soleSizeLeft = finalImport.fitsSpecification[fitsNumber].footSizeLeft
                finalImport.fitsSpecification[fitsNumber].soleSizeRight = finalImport.fitsSpecification[fitsNumber].footSizeRight
            }

            finalImport.fitsSpecification[fitsNumber].model = modelChoices.get(gender).find(element => element.displayValue === finalImport.fitsSpecification[fitsNumber].model.displayValue) || ''

            // Get uppermaterials
            const uppermatChoices = upperMaterialOptions.get(gender).get(finalImport.fitsSpecification[fitsNumber].model.key)

            // find the changed material
            finalImport.fitsSpecification[fitsNumber].upperMaterial = uppermatChoices.find(element => element === finalImport.fitsSpecification[fitsNumber].upperMaterial) || ''
            finalImport.fitsSpecification[fitsNumber].finishingShape = finishingShapeOptions.find(element => element === finalImport.fitsSpecification[fitsNumber].finishingShape) || ''
            const coverMat = fitsCoverMaterials.find(element => element === finalImport.fitsSpecification[fitsNumber].coverMaterial) || ''

            // if material not found, try to find something similar.
            if (coverMat === '') {
                // remove microfiber from string, since its no longer there.
                let splittedCoverMaterial = event.fitsSpecification[fitsNumber].coverMaterial.toLowerCase().split('microfiber ')

                // remove spaces.
                splittedCoverMaterial = splittedCoverMaterial.toString().split(' ')

                // gather all materials matching the first string.
                const materials = fitsCoverMaterials.filter(element => element.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(splittedCoverMaterial[0]))

                // finalize the material with one type that matches with the second string.
                finalImport.fitsSpecification[fitsNumber].coverMaterial = materials.find(element => element.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(splittedCoverMaterial.pop())) || ''
            } else {
                finalImport.fitsSpecification[fitsNumber].coverMaterial = coverMat
            }
        })

        finalImport.soleResult.forEach((fitsResultData, i) => {
            const minimumValue = diagnosis.fasciPad === 'true' || diagnosis.ascPad === 'true' ? 9 : 6
            const diff = Math.abs(finalImport.soleResult[i].left.asc - finalImport.soleResult[i].right.asc)
            if (finalImport.soleResult[i].left.asc > finalImport.soleResult[i].right.asc) {
                finalImport.soleResult[i].left.asc = minimumValue + diff
                finalImport.soleResult[i].right.asc = minimumValue

                finalImport.soleResultSuggestion[i].left.asc = minimumValue
                finalImport.soleResultSuggestion[i].right.asc = minimumValue
            } else {
                finalImport.soleResult[i].right.asc = minimumValue + diff
                finalImport.soleResult[i].left.asc = minimumValue

                finalImport.soleResultSuggestion[i].left.asc = minimumValue
                finalImport.soleResultSuggestion[i].right.asc = minimumValue
            }
        })
    }

    if (event.orderType !== 'fitsCustom' && event.orderType !== 'fitsComfort' && event.orderType !== 'fits') {
        // loop through solespecifications
        finalImport.soleSpecification.forEach((sole, i) => {
            const soleSpec = i

            if (event.version <= '1.6.1') {
                for (const key in sole) {
                    if (key === 'millingSize') {
                        if (finalImport.soleSpecification[soleSpec].block.includes('3D PU')) {
                            finalImport.soleSpecification[soleSpec][key] = 38
                        }

                        // empty milling size if 16 is filled
                        if (finalImport.soleSpecification[soleSpec].millingSize === 16) {
                            finalImport.soleSpecification[soleSpec].millingSize = ''
                        }
                    }
                }
            }

            finalImport.soleSpecification[soleSpec].millingSize = finalImport.soleSpecification[soleSpec].millingSize === 32 ? 30 : finalImport.soleSpecification[soleSpec].millingSize

            let block = ''
            if (event.version <= '1.6.2') {
                block = soleBlockChoices.find(element => element.key === finalImport.soleSpecification[soleSpec].block) || ''

                // check if block is exeption EVA 50 sh
                if (finalImport.soleSpecification[soleSpec].block === 'EVA 50sh zwart' || finalImport.soleSpecification[soleSpec].block === 'EVA 50 sh - zwart') {
                    block = soleBlockChoices.find(element => element.key === 'EVA 50 sh')
                }
            } else {
                block = soleBlockChoices.find(element => element.key === finalImport.soleSpecification[soleSpec].block.key) || ''

                // check if block is exeption EVA 50 sh
                if (finalImport.soleSpecification[soleSpec].block.key === 'EVA 50 sh - zwart') {
                    block = soleBlockChoices.find(element => element.key === 'EVA 50 sh')
                }
            }

            if (block === '') {
                let closestBlockMatch = {}
                // get closest match
                soleBlockChoices.forEach(block => {
                    const matchNumber = distance(finalImport.soleSpecification[soleSpec].block.replace(/[^a-zA-Z]+/g, ''), block.key.replace(/[^a-zA-Z]+/g, ''))
                    if (matchNumber < 4) {
                        closestBlockMatch = block
                    }
                })

                if (closestBlockMatch.key === undefined) {
                    if (finalImport.soleSpecification[soleSpec].block.includes('40sh Middel')) {
                        finalImport.soleSpecification[soleSpec].block = soleBlockChoices.find(element => element.key === 'Combi 40 sh')
                    }
                } else {
                    finalImport.soleSpecification[soleSpec].block = closestBlockMatch
                }
            } else {
                finalImport.soleSpecification[soleSpec].block = block
            }

            const groundSole = groundSolePatternTypes.find(element => (element.name === finalImport.soleSpecification[soleSpec].groundSole.name) && (element.publock === finalImport.soleSpecification[soleSpec].block.key.includes('3D PU'))) || ''

            if (groundSole === '') {
                if (finalImport.soleSpecification[soleSpec].groundSole.name.includes(' (Alleen voor OS)')) {
                    finalImport.soleSpecification[soleSpec].groundSole = groundSolePatternTypes.find(element => element.name === finalImport.soleSpecification[soleSpec].groundSole.name.replace(' (Alleen voor OS)', '')) || ''
                } else if (finalImport.soleSpecification[soleSpec].groundSole.name.includes('GP Kids')) {
                    finalImport.soleSpecification[soleSpec].groundSole = groundSolePatternTypes.find(element => element.name === 'PLT Kids') || ''
                } else if (finalImport.soleSpecification[soleSpec].groundSole.name === 'PLT Smal Sport') {
                    finalImport.soleSpecification[soleSpec].groundSole = groundSolePatternTypes.find(element => element.name === 'PLT Small Sport') || ''
                }
            } else {
                finalImport.soleSpecification[soleSpec].groundSole = groundSole
            }

            if (finalImport.soleSpecification[0].block.key !== '3D Sport Printzool') {
                const coverMat = soleCoverMaterials.find(element => element === finalImport.soleSpecification[soleSpec].coverMaterial) || ''
                // if material not found, try to find something similar.
                if (coverMat === '') {
                    // remove microfiber from string, since its no longer there.
                    let splittedCoverMaterial = event.soleSpecification[soleSpec].coverMaterial.toLowerCase().split('microfiber ')

                    // remove spaces.
                    splittedCoverMaterial = splittedCoverMaterial.toString().replace(',', '').split(' ')

                    // gather all materials matching the first string.
                    const materials = soleCoverMaterials.filter(element => element.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(splittedCoverMaterial[0]))

                    const lastMaterialValue = splittedCoverMaterial.pop()
                    const findFinalMaterial = materials.find(element => element.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(lastMaterialValue))

                    // finalize the material with one type that matches with the second string.
                    finalImport.soleSpecification[soleSpec].coverMaterial = findFinalMaterial || ''
                } else {
                    finalImport.soleSpecification[soleSpec].coverMaterial = coverMat
                }
            }

            if (event.version <= '1.6.2') {
                finalImport.soleSpecification[soleSpec].soleSizeLeft = `${finalImport.soleSpecification[soleSpec].soleSizeLeft}`.replace(',', '.')
                finalImport.soleSpecification[soleSpec].soleSizeRight = `${finalImport.soleSpecification[soleSpec].soleSizeRight}`.replace(',', '.')
                finalImport.soleSpecification[soleSpec].footSizeLeft = `${finalImport.soleSpecification[soleSpec].footSizeLeft}`.replace(',', '.')
                finalImport.soleSpecification[soleSpec].footSizeRight = `${finalImport.soleSpecification[soleSpec].footSizeRight}`.replace(',', '.')
            }

            if (finalImport.soleSpecification[soleSpec].hypoallergenicGlue === undefined) finalImport.soleSpecification[soleSpec].hypoallergenicGlue = false
        })

        // loop through soleresult
        finalImport.soleResult.forEach((soleResultData, i) => {
            for (const key in soleResultData.left) {
                if (key === 'fasciPad' || key === 'ascPad') {
                    if (typeof soleResultData.left[key] === 'boolean') {
                        finalImport.soleResult[i].left[key] = `${finalImport.soleResult[i].left[key]}`
                    }
                    if (typeof soleResultData.right[key] === 'boolean') {
                        finalImport.soleResult[i].right[key] = `${finalImport.soleResult[i].right[key]}`
                    }
                }

                if (key === 'CV') {
                    if (finalImport.soleResult[i].left[key] > 0) {
                        const diff = 10 - finalImport.soleResult[i].left[key]
                        if (diff < 0) {
                            finalImport.soleResult[i].left[key] = 10
                        }
                    }
                    if (finalImport.soleResult[i].right[key] > 0) {
                        const diff = 10 - finalImport.soleResult[i].right[key]
                        if (diff < 0) {
                            finalImport.soleResult[i].right[key] = 10
                        }
                    }
                }

                // Move old PA to LFW and remove old values aswell as suggestions
                if (event.version <= '1.8.0') {
                    if (key === 'PA') {
                        finalImport.soleResult[i].left.LFW = finalImport.soleResult[i].left.PA
                        finalImport.soleResult[i].right.LFW = finalImport.soleResult[i].right.PA

                        finalImport.soleResultSuggestion[i].left.LFW = finalImport.soleResultSuggestion[i].left.PA
                        finalImport.soleResultSuggestion[i].right.LFW = finalImport.soleResultSuggestion[i].right.PA

                        delete finalImport.soleResult[i].left.PA
                        delete finalImport.soleResult[i].right.PA
                        delete finalImport.soleResultSuggestion[i].left.PA
                        delete finalImport.soleResultSuggestion[i].right.PA
                    }

                    if (key === 'pelotte') {
                        if (finalImport.soleResult[i].left.pelotte !== '' && finalImport.soleResult[i].left.pelotte > 0) {
                            finalImport.soleResult[i].left.pelotteType = 'pelotte'
                        }
                        if (finalImport.soleResult[i].right.pelotte !== '' && finalImport.soleResult[i].right.pelotte > 0) {
                            finalImport.soleResult[i].right.pelotteType = 'pelotte'
                        }
                    }

                    if (key === 'MIC') {
                        if (finalImport.soleResultSuggestion[i].left.MICPosition === undefined) {
                            finalImport.soleResult[i].left.MICPosition = finalImport.diagnosis.diagnosis.MICPosition
                        }
                        if (finalImport.soleResultSuggestion[i].right.MICPosition === undefined) {
                            finalImport.soleResult[i].right.MICPosition = finalImport.diagnosis.diagnosis.MICPosition
                        }
                    }
                }
            }
        })

        if (finalImport.soleAddition !== undefined) {
            finalImport.soleAddition.addition.forEach((addition, i) => {
                finalImport.soleAddition.addition[i].name = addition.name.replace('Shockfoam', 'PPT')

                if (finalImport.soleAddition.addition[i].name.includes('Softlux')) {
                    finalImport.soleAddition.addition[i].name = ''
                }
            })
        }
    }
    // check soletherapy
    return finalImport
}

/**
 * Change insole import to work with the fits version.
 *
 * @param {Object} event - full JSON import file.
 * @return state
 */
function importSoleToFits (event) {
    const finalImport = deepCopy(event)

    finalImport.general.fittingLocation.id = parseInt(finalImport.general.fittingLocation.id)
    finalImport.general.receiveLocation.id = parseInt(finalImport.general.receiveLocation.id)

    //  fix import for old version
    if (event.version <= '1.4.5') {
        // make sole result and sole result suggestion an array, that is used like an array now.
        finalImport.soleResult = [event.soleResult, event.soleResult, event.soleResult]
        finalImport.soleResultSuggestion = [event.soleResultSuggestion, event.soleResultSuggestion, event.soleResultSuggestion]
    }

    // Fix preffered support to be the same as gender because default it was male.
    if (event.version <= '1.5.9') {
        finalImport.general.prefferedSupport = finalImport.general.gender
    }

    if (finalImport.diagnosis.extraDiagnosis === undefined) {
        finalImport.diagnosis.extraDiagnosis = []
    }

    // if advised fasci or ascpad, ASC 9mm else ASC 6mm
    // show 507 error when own choice ASC <  6mm
    const diagnosis = data.getDiagnoseByName(finalImport.diagnosis.diagnosis.name)
    const init = deepCopy(initialStateFits)
    finalImport.fitsSpecification = [
        { ...init },
        { ...init },
        { ...init }
    ]

    // run through the solespecs and update the sole/ foot sizes
    event.soleSpecification.forEach((element, i) => {
        const fitsNumber = i

        finalImport.fitsSpecification[fitsNumber].fitsData.soleSizeLeft = `${element.soleSizeLeft}`.replace(',', '.')
        finalImport.fitsSpecification[fitsNumber].fitsData.soleSizeRight = `${element.soleSizeRight}`.replace(',', '.')
        finalImport.fitsSpecification[fitsNumber].fitsData.footSizeLeft = `${element.footSizeLeft}`.replace(',', '.')
        finalImport.fitsSpecification[fitsNumber].fitsData.footSizeRight = `${element.footSizeRight}`.replace(',', '.')
    })

    finalImport.soleResult.forEach((fitsResultData, i) => {
        const minimumValue = diagnosis.fasciPad === 'true' || diagnosis.ascPad === 'true' ? 9 : 6
        const diff = Math.abs(finalImport.soleResult[i].left.asc - finalImport.soleResult[i].right.asc)
        if (finalImport.soleResult[i].left.asc > finalImport.soleResult[i].right.asc) {
            finalImport.soleResult[i].left.asc = minimumValue + diff
            finalImport.soleResult[i].right.asc = minimumValue

            finalImport.soleResultSuggestion[i].left.asc = minimumValue
            finalImport.soleResultSuggestion[i].right.asc = minimumValue
        } else {
            finalImport.soleResult[i].right.asc = minimumValue + diff
            finalImport.soleResult[i].left.asc = minimumValue

            finalImport.soleResultSuggestion[i].left.asc = minimumValue
            finalImport.soleResultSuggestion[i].right.asc = minimumValue
        }
    })

    // diagnosis will be handled in the store, because it will send an error message.
    if (diagnosis !== undefined) {
        finalImport.soleResultSuggestion.forEach((elements, i) => {
            const soleResNumber = i

            finalImport.soleResultSuggestion[soleResNumber].left.cv = 23
            finalImport.soleResultSuggestion[soleResNumber].right.cv = 23

            for (const key in elements.left) {
                switch (key) {
                case 'fasciPad': case 'ascPad':
                    finalImport.soleResultSuggestion[soleResNumber].left[key] = diagnosis[key]
                    finalImport.soleResultSuggestion[soleResNumber].right[key] = diagnosis[key]
                    finalImport.soleResult[soleResNumber].left[key] = diagnosis[key]
                    finalImport.soleResult[soleResNumber].right[key] = diagnosis[key]
                    break
                default:
                    break
                }
            }
        })

        const fullDiagnosis = deepCopy(finalImport.diagnosis)

        if (diagnosis.therapyMessage !== 0) {
            fullDiagnosis.therapyMessage = 'FITS Therapie'
            finalImport.diagnosis = fullDiagnosis
        } else {
            fullDiagnosis.therapyMessage = 'Geen FITS therapie'
            finalImport.diagnosis = fullDiagnosis
        }
    }

    // check soletherapy
    return finalImport
}

/**
 * Change fits import to work with the insole version.
 *
 * @param {Object} event - full JSON import file.
 * @return state
 */
function importFitsToSole (event) {
    const finalImport = deepCopy(event)

    finalImport.general.fittingLocation.id = parseInt(finalImport.general.fittingLocation.id)
    finalImport.general.receiveLocation.id = parseInt(finalImport.general.receiveLocation.id)

    //  fix import for old version
    if (event.version <= '1.4.5') {
        // make sole result and sole result suggestion an array, that is used like an array no
        finalImport.soleResult = [event.soleResult, event.soleResult, event.soleResult]
        finalImport.soleResultSuggestion = [event.soleResultSuggestion, event.soleResultSuggestion, event.soleResultSuggestion]
    }

    if (finalImport.diagnosis.extraDiagnosis === undefined) {
        finalImport.diagnosis.extraDiagnosis = []
    }

    if (finalImport.diagnosis.completePair === undefined) {
        finalImport.diagnosis.completePair = 'both'
    }

    const diagnosis = data.getDiagnoseByName(finalImport.diagnosis.diagnosis.name)
    const init = deepCopy(initialStateSole)
    finalImport.soleSpecification = [
        { ...init },
        { ...init },
        { ...init }
    ]

    // run through the solespecs and update the sole/ foot sizes
    event.fitsSpecification.forEach((element, i) => {
        const soleResNumber = i

        if (event.version <= '1.5.9') {
            finalImport.soleSpecification[soleResNumber].soleData.soleSizeLeft = `${element.footSizeLeft}`.replace(',', '.')
            finalImport.soleSpecification[soleResNumber].soleData.soleSizeRight = `${element.footSizeRight}`.replace(',', '.')
        } else {
            finalImport.soleSpecification[soleResNumber].soleData.soleSizeLeft = `${element.soleSizeLeft}`.replace(',', '.')
            finalImport.soleSpecification[soleResNumber].soleData.soleSizeRight = `${element.soleSizeRight}`.replace(',', '.')
        }

        finalImport.soleSpecification[soleResNumber].soleData.footSizeLeft = `${element.footSizeLeft}`.replace(',', '.')
        finalImport.soleSpecification[soleResNumber].soleData.footSizeRight = `${element.footSizeRight}`.replace(',', '.')
    })

    // diagnosis will be handled in the store, because it will send an error message.
    if (diagnosis !== undefined) {
        finalImport.soleResultSuggestion.forEach((elements, i) => {
            const soleResNumber = i
            for (const key in elements.left) {
                switch (key) {
                case 'cv':
                    finalImport.soleResultSuggestion[soleResNumber].left[key] = diagnosis.CV // check diagnosis list.
                    finalImport.soleResultSuggestion[soleResNumber].right[key] = diagnosis.CV // check diagnosis list.
                    break
                case 'asc':
                    finalImport.soleResultSuggestion[soleResNumber].left[key] = diagnosis.ASC // check diagnosis list.
                    finalImport.soleResultSuggestion[soleResNumber].right[key] = diagnosis.ASC // check diagnosis list.
                    break
                case 'fasciPad': case 'ascPad':
                    // PUT TO FALSE INSTEAD OF DIAGNOSE BECAUSE, THE DEFAULT BLOCK WILL BE OVERWRITTEN WHEN A DIFFRENT BLOCK IS USED.
                    // AND DEFAULT BLOCK 3D PU BLOCK DOES NOT SUPPORT FASCIPAD AND ASCPAD
                    finalImport.soleResultSuggestion[soleResNumber].left[key] = 'false'
                    finalImport.soleResultSuggestion[soleResNumber].right[key] = 'false'
                    finalImport.soleResult[soleResNumber].left[key] = 'false'
                    finalImport.soleResult[soleResNumber].right[key] = 'false'
                    break
                default:
                    break
                }
            }
        })

        const fullDiagnosis = deepCopy(finalImport.diagnosis)

        if (diagnosis.therapyMessage !== 0) {
            fullDiagnosis.therapyMessage = 'Zolentherapie'
            finalImport.diagnosis = fullDiagnosis
        } else {
            fullDiagnosis.therapyMessage = 'Geen zolentherapie'
            finalImport.diagnosis = fullDiagnosis
        }
    }

    // check soletherapy
    return finalImport
}

export { importSoleToFits, importFitsToSole, importSoleAndFits }
