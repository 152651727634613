import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({
    root: {
        height: '100%'
    },
    loading: {
        margin: 0
    },
    accordionError: {
        backgroundColor: theme.palette.error.light
    },
    accordionSuccess: {
        backgroundColor: theme.palette.success.light
    },
    titleError: {
        fontWeight: 'bold',
        color: theme.palette.error.main
    },
    titleSuccess: {
        fontWeight: 'bold',
        color: theme.palette.success.main
    },
    autoComplete: {
        marginTop: 16
    },
    title: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    spacingTop: {
        marginTop: theme.spacing(2)
    },
    tafelberg: {
        marginTop: theme.spacing(2),
        fontWeight: 'bold',
        color: theme.palette.error.main
    },
    soleSizeContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderRadius: 5,
        backgroundColor: theme.palette.grey[50]
    },
    copySole: {
        borderColor: '#0094D1',
        color: '#0094D1'
    },
    buttonGrid: {
        height: '100%'
    },
    fabText: {
        paddingTop: 20
    },
    soleThickness: {
        paddingTop: 10,
        color: theme.palette.text.light
    },
    closeRightsole: {
        marginTop: 40
    },
    checkBox: {
        marginTop: 16,
        marginLeft: 0,
        marginRight: 0
    },
    checkBoxBottom: {
        marginTop: -20,
        marginLeft: 0,
        marginRight: 0
    }
}))

export default styles
